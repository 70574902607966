import React, { useState, useEffect } from "react";

// Audio files (replace with your actual file paths)
const beepSound = new Audio("beep.mp3");
const intervalSounds = [
  "interval1.mp3",
  "interval2.mp3",
  "interval3.mp3",
  "interval4.mp3",
  "interval5.mp3",
  "interval6.mp3",
  "interval7.mp3",
  "interval8.mp3",
  "interval9.mp3",
  "interval10.mp3",
];

const TimerApp = () => {
  const [activeTimer, setActiveTimer] = useState(null); // Tracks active button
  const [timeLeft, setTimeLeft] = useState(0); // Time remaining for the timer
  const [isInterval, setIsInterval] = useState(false); // Flag for second interval (45s/60s)
  const [intervalIndex, setIntervalIndex] = useState(0); // Tracks current interval sound
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);

  const playBeepSound = () => {
    beepSound.play();
  };

  const playIntervalSound = () => {
    const currentSound = new Audio(intervalSounds[intervalIndex]);
    currentSound.play();
    // Update to the next sound, looping back if at the end
    setIntervalIndex((prevIndex) => (prevIndex + 1) % intervalSounds.length);
  };

  const onButtonClick = (buttonIndex) => {
    setAreButtonsDisabled(true);
    setTimeout(() => {
      startTimer(buttonIndex)
    }, 2000);
  }

  const startTimer = (buttonIndex) => {
    // Start the timer sequence based on the button
    setActiveTimer(buttonIndex);
    setTimeLeft(buttonIndex === 0 ? 15 : 30); // Initial timer
    setIsInterval(false);
    playBeepSound(); // Play beep at the start
  };

  useEffect(() => {
    if (timeLeft <= 0 && activeTimer !== null) {
      if (!isInterval) {
        // Transition to interval timer
        setTimeLeft(activeTimer === 0 ? 45 : 60);
        setIsInterval(true);
        playBeepSound(); // Play beep
        playIntervalSound(); // Play interval sound
      } else {
        // Timer completed
        setActiveTimer(null); // Stop timers
        setAreButtonsDisabled(false);
      }
    }

    // Countdown effect
    if (timeLeft > 0) {
      const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearInterval(timer); // Clean up timer
    }
  }, [timeLeft, activeTimer, isInterval]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Speaking Section</h1>
      <div>
      {["Independent", "Integrated 1", "Integrated 2", "Integrated 3"].map((label, index) => (
          <button
            key={index}
            onClick={() => onButtonClick(index)}
            disabled={areButtonsDisabled}
            style={{
              margin: "10px",
              padding: "10px 20px",
              fontSize: "16px",
              cursor: activeTimer !== null ? "not-allowed" : "pointer",
            }}
          >
            {label}
          </button>
        ))}
      </div>
      {(activeTimer !== null || areButtonsDisabled) && (
        <div style={{ display: 'flex', marginTop: '80px', justifyContent: 'center', alignItems: 'flex-start' }}>
          <div style={{ backgroundColor: '#dce0de', width: '250px', borderRadius: '8px' }}>
            <div style={{ borderBottom: 'solid', height: '20px', paddingBottom: "10px", }}>
              <h3>
                {areButtonsDisabled && (isInterval ? "Speak now 🔴" : "Prepare your response")}
              </h3>
            </div>
              <h2>{timeLeft}s</h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimerApp;